import React from 'react'
import '../styles/Footer.css'

export default function Footer() {
    return (
        <div className='footer'>
            <p>2020 Swimli All Rights Reserved</p>
        </div>
    )
}
